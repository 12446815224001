<template>
  <main class="find_password">
    <div class="find_password_form b_form">
      <h3>비밀번호 찾기</h3>
      <p>
        Blue Button의 비밀번호를 찾으실 경우 가입 시 등록한 아이디 및 이름,
        이메일 주소를 입력해 주세요.
      </p>
      <form @submit.prevent="formSubmit">
        <ValidationProvider name="아이디" ref="refId" rules="required">
          <input
            type="id"
            name="id"
            v-model="id"
            placeholder="아이디"
            ref="refIdInput"
          />
        </ValidationProvider>
        <ValidationProvider name="이름" ref="refName" rules="required">
          <input
            type="text"
            name="name"
            v-model="name"
            placeholder="이름"
            ref="refNameInput"
          />
        </ValidationProvider>
        <ValidationProvider name="이메일" ref="refEmail" rules="required|email">
          <input
            type="text"
            name="email"
            v-model="email"
            placeholder="이메일 주소"
            ref="refEmailInput"
          />
        </ValidationProvider>
        <button type="submit">비밀번호 찾기</button>
      </form>
      <div class="find_password_registers register">
        <router-link to="/login">로그인</router-link> |
        <router-link to="/findid">아이디 찾기</router-link>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      id: "",
      name: "",
      email: "",
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    async formSubmit() {
      this.validation();
    },
    async validation() {
      const refId = await this.$refs.refId.validate();
      if (!refId.valid) {
        alert(refId.errors[0]);
        this.$refs.refIdInput.focus();
        return false;
      }
      const refName = await this.$refs.refName.validate();
      if (!refName.valid) {
        alert(refName.errors[0]);
        this.$refs.refNameInput.focus();
        return false;
      }
      const refEmail = await this.$refs.refEmail.validate();
      if (!refEmail.valid) {
        alert(refEmail.errors[0]);
        this.$refs.refEmailInput.focus();
        return false;
      }
      return true;
    },
  },
};
</script>
